// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-coop-js": () => import("./../../../src/pages/coop.js" /* webpackChunkName: "component---src-pages-coop-js" */),
  "component---src-pages-curation-js": () => import("./../../../src/pages/curation.js" /* webpackChunkName: "component---src-pages-curation-js" */),
  "component---src-pages-escrow-js": () => import("./../../../src/pages/escrow.js" /* webpackChunkName: "component---src-pages-escrow-js" */),
  "component---src-pages-fellowship-js": () => import("./../../../src/pages/fellowship.js" /* webpackChunkName: "component---src-pages-fellowship-js" */),
  "component---src-pages-governance-js": () => import("./../../../src/pages/governance.js" /* webpackChunkName: "component---src-pages-governance-js" */),
  "component---src-pages-incubator-js": () => import("./../../../src/pages/incubator.js" /* webpackChunkName: "component---src-pages-incubator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-juror-js": () => import("./../../../src/pages/juror.js" /* webpackChunkName: "component---src-pages-juror-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-missing-locale-redirect-js": () => import("./../../../src/pages/MissingLocaleRedirect.js" /* webpackChunkName: "component---src-pages-missing-locale-redirect-js" */),
  "component---src-pages-moderate-js": () => import("./../../../src/pages/moderate.js" /* webpackChunkName: "component---src-pages-moderate-js" */),
  "component---src-pages-oracle-js": () => import("./../../../src/pages/oracle.js" /* webpackChunkName: "component---src-pages-oracle-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-scout-js": () => import("./../../../src/pages/scout.js" /* webpackChunkName: "component---src-pages-scout-js" */),
  "component---src-pages-token-js": () => import("./../../../src/pages/token.js" /* webpackChunkName: "component---src-pages-token-js" */),
  "component---src-pages-treasury-js": () => import("./../../../src/pages/treasury.js" /* webpackChunkName: "component---src-pages-treasury-js" */),
  "component---src-templates-bio-js": () => import("./../../../src/templates/bio.js" /* webpackChunkName: "component---src-templates-bio-js" */)
}

